import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Reveal from "components/utils/Reveal"
import ImageText from "components/sections/ImageText"
import Layout from "components/Layout"
import SEO from "components/Seo"
import { Label, Paragraph } from "components/atoms/Typography"
import Box from "components/atoms/Box"

import SectionStyling from "components/atoms/SectionStyling"
import StandardPadding from "components/atoms/StandardPadding"
import BackgroundIcon from "components/atoms/BackgroundIcon"

const Section = ({ title, children }) => {
  return (
    <Box color="white" maxWidth="672px" mb={7}>
      <Label mb="12px" fontSize="11px" color="teal">
        {title}
      </Label>
      {children}
    </Box>
  )
}

const List = ({ items }) => {
  return (
    <>
      {items.map((item, i) => (
        <span key={i}>
          {i !== 0 && <span>, </span>}
          {item}
        </span>
      ))}
    </>
  )
}

const BackgroundIconWrapper = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: 100vw;
  height: 360px;
  overflow: hidden;
`

const AccentImage = styled.div`
  background-image: url("/team-member-accent.png");
  background-position: right center;
  background-repeat: no-repeat;
`

const TeamMemberPage = ({ data }) => {
  const {
    image,
    name,
    positionTitle,
    linkedInLink,
    description,
    shortDescription,
    currentInvestments,
    realizedInvestments,
    selectPastInvestments,
  } = data.contentfulTeamMember
  const site = data.contentfulSite

  const bottomSection =
    description ||
    currentInvestments ||
    realizedInvestments ||
    selectPastInvestments

  return (
    <Layout headerInvert headerBg="darkBlue" header={site.header}>
      <SEO siteTitle={site.title} title={name} />
      <ImageText
        isTeamMember
        idx={0}
        image={image}
        imageLocation="left"
        imageLocationMobile="top"
        sectionStyling={{
          isDarkBackground: true,
          backgroundColor: "darkBlue",
        }}
        sectionText={{
          teamMemberPage: true,
          subtitle: positionTitle,
          title: name,
          description: shortDescription,
        }}
        linkedInLink={linkedInLink}
      />
      <BackgroundIconWrapper>
        <BackgroundIcon
          zIndex={1}
          top={0}
          left={-65}
          width={495}
          height={360}
        />
      </BackgroundIconWrapper>
      {bottomSection && (
        <SectionStyling
          rightBackgroundImage={data.file.childImageSharp}
          isDarkBackground
          backgroundColor="darkBlue"
        >
          <Reveal>
            <StandardPadding py={{ _: 6, lg: 8 }}>
              {description && (
                <Section title="Background">
                  <Paragraph
                    color="white"
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html,
                    }}
                  />
                </Section>
              )}
              {currentInvestments && (
                <Section title="Current Investments">
                  <Paragraph color="white">
                    <List items={currentInvestments} />
                  </Paragraph>
                </Section>
              )}
              {realizedInvestments && (
                <Section title="Realized Investments">
                  <Paragraph color="white">
                    <List items={realizedInvestments} />
                  </Paragraph>
                </Section>
              )}
              {selectPastInvestments && (
                <Section title="Select Past Investments">
                  <Paragraph color="white">
                    <List items={selectPastInvestments} />
                  </Paragraph>
                </Section>
              )}
            </StandardPadding>
          </Reveal>
        </SectionStyling>
      )}
    </Layout>
  )
}

export default TeamMemberPage

export const query = graphql`
  query TeamMemberBySlug($slug: String!) {
    file(relativePath: { eq: "team-member-background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    contentfulTeamMember(slug: { eq: $slug }) {
      image {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      name
      positionTitle
      linkedInLink
      description {
        childMarkdownRemark {
          html
        }
      }
      currentInvestments
      realizedInvestments
      selectPastInvestments
    }
    contentfulSite {
      title
      header {
        ... on ContentfulDefaultHeader {
          ...DefaultHeader
        }
      }
    }
  }
`
